module.exports = {
  defaultTitle: 'ShoulderToShoulder.eth',
  logo: 'https://dejitarushirudo.io/wp-content/uploads/2022/07/cropped-Untitled-7-01-32x32.png',
  author: 'Sangha Research',
  url: 'https://shouldertoshoulder.io',
  legalName: 'Shoulder to Shoulder',
  defaultDescription: 'We are sangha researchers. Our goal is to share our findings.',
  socialLinks: {
    twitter: 'https://twitter.com/Dejitaru_Tsuka?',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: 'appId',
    twitter: '@Dejitaru_Tsuka',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
};
